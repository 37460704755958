.snackbar {
  visibility: hidden; /*Hidden by default. Visible on click*/
  position: absolute;
  right: -120px;
  top: 28px;
  transform: translate(-50%, -50%);
  width: 350px;
  display: flex;
  align-items: center;
  text-align: center;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
.snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  /* -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s; */
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

.snackbar-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.snackbar-hidden {
  opacity: 0;
  transition: all 500ms linear 2s;
}