/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  width: 50%;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: rgb(237, 235, 233);
  color: #444;
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-radius: 2px;
  box-shadow: 0 8px 6px -6px rgba(14, 13, 13, 0.350);
  /*box-shadow: '0px 1.6px 3.6px rgba(0, 0, 0, 0.132), 0px 0.3px 0.9px rgba(0, 0, 0, 0.108)';*/
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: #ccc; 
}

/* Style the accordion content title */
.accordion__title {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-weight: 400;
  font-size: 12px;
  padding: 18px;
}

.search__highlight {
  background: yellow;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  color: #888;
  font-style: italic;
}

/* [contentEditable] {padding:12px; background:#eee; } */

.sub-footer {
  width: 100%;
  height: 35px;
  background-color: #edebe9;
}

.sub-s-footer {
  width: 70%;
  line-height: 35px;
  margin: auto;
  color:#605e5c;
  display: flex;
}

.footer-logo {
  line-height: auto;
  width: 30%;
  font-size: 14px;
}

.footer-nav {
  width: 100%;
  height: 35px;
  background-color: #edebe9;
  line-height: 35px;
  margin: auto;
  color:#605e5c;
  display: flex;
  direction: rtl;
}

.footer-nav ul {
  text-align: center;
}

.footer-nav ul li {
  display: inline;
  line-height: auto;
  padding: 0px 5%;
}

.footer-nav ul li a {
  text-decoration: none;
  font-size: 14px;
  color:#605e5c;
}

.footer-nav ul li a:hover {
  color: black;
  cursor: pointer;
}
